import { INPUT_VALIDATION } from '../../config/constants';

export default () => ({
  maxThrust: INPUT_VALIDATION.MAX_THRUST_MAX,
  basic: {
    sog: 0,
    cog: 0,
    tws: 0,
    twa: 0,
  },
  advanced: {
    rotorSailAmount: 6,
    rotorSail: {
      height: 35,
      diameter: 5,
      size: '5x35',
      maxRPM: 180,
      forwardForceLimit: 350,
      lateralForceLimit: 350,
    },
    other: {
      propulsionEfficiencyPercentage: 70,
      airTemperature: 20,
      airPressure: 101300,
    },
  },
});
