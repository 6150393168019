import initialState from './scenario.state';
import { ACTIONS, MUTATIONS } from './scenario.constants';
import scenarioRemoteService from './scenario-remote-service';
import { ACTIONS as PANELS_ACTIONS } from '../panels/panels.constants';

export default {
  state: initialState(),
  actions: {
    [ACTIONS.SET_BASIC_INPUT]: ({ commit }, basicInput) => {
      commit(MUTATIONS.SET_BASIC_INPUT, basicInput);
    },
    [ACTIONS.CALCULATE]: ({ state, dispatch }) => scenarioRemoteService
      .calculate(state.basic, state.advanced, state.maxThrust)
      .then(({ data }) => {
        dispatch(PANELS_ACTIONS.SET_SINGLE_OPERATION_OUTPUT, data);
      })
      .catch((err) => {
        // TODO: show TOAST
        console.error(err);
      }),
    [ACTIONS.UPDATE_MAX_THRUST]: ({ commit }, maxThrust) => {
      commit(MUTATIONS.UPDATE_MAX_THRUST, maxThrust);
    },
  },
  mutations: {
    [MUTATIONS.SET_BASIC_INPUT]: (state, basicInput) => {
      state.basic = basicInput;
    },
    [MUTATIONS.UPDATE_MAX_THRUST]: (state, maxThrust) => {
      state.maxThrust = maxThrust;
    },
  },
};
