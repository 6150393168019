<template>
  <v-bottom-navigation grow absolute v-model="selected">
    <v-btn value="operation" to="/operation">
      <span>Operation</span>
      <v-icon>mdi-cog-sync</v-icon>
    </v-btn>
    <v-btn value="graphs" to="/graphs" disabled>
      <span>Graphs</span>
      <v-icon>mdi-chart-multiple</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
export default {
  data: () => ({
    selected: 'operation',
  }),
};
</script>
