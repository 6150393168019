<template>
  <div>
    <span class="text-h6">Control</span>

    <div>
      <v-btn-toggle v-model="sailMode" dense tile>
        <v-btn>
          Auto
        </v-btn>
        <v-btn disabled>
          Sail
        </v-btn>
        <v-btn disabled>
          Idle
        </v-btn>
        <v-btn disabled>
          Off
        </v-btn>
      </v-btn-toggle>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    sailMode: 0,
  }),
};
</script>
<style scoped>
.v-btn-toggle {
  flex-direction: column;
  width: 100%;
}
</style>
