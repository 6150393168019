export const MODULE_NAME = 'scenario';

export const ACTIONS = {
  SET_BASIC_INPUT: `${MODULE_NAME}/SET_BASIC_INPUT`,
  CALCULATE: `${MODULE_NAME}/CALCULATE`,
  UPDATE_MAX_THRUST: `${MODULE_NAME}/UPDATE_MAX_THRUST`,
};

export const MUTATIONS = {
  SET_BASIC_INPUT: `${MODULE_NAME}/SET_BASIC_INPUT`,
  UPDATE_MAX_THRUST: `${MODULE_NAME}/UPDATE_MAX_THRUST`,
};
