<template>
  <div>
    <span class="text-h6">Course</span>

    <v-card outlined tile>
      <v-container>
        <v-row dense>
          <v-col :cols="6">
            <OutputTextField
              :minWidth="60"
              suffix="kn"
              :value="basic.sog"
              :formatter="(val) => formatter(val, 1)"
            >
              <template v-slot:label>
                SOG
              </template>
            </OutputTextField>
          </v-col>
          <v-col :cols="6">
            <OutputTextField
            :minWidth="60"
            suffix="deg"
            :value="basic.cog"
            :formatter="(val) => formatter(val, 0)"
            >
              <template v-slot:label>
                COG
              </template>
            </OutputTextField>
          </v-col>

          <v-col :cols="6">
            <OutputTextField
              :minWidth="60"
              suffix="deg"
              :value="basic.cog"
              :formatter="(val) => formatter(val, 0)"
            >
              <template v-slot:label>
                HDG
              </template>
            </OutputTextField>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <CourseGauge />
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import OutputTextField from '../shared/OutputTextField.vue';
import CourseGauge from './CourseGauge.vue';
import { MODULE_NAME as SCENARIO } from '../../../store/scenario/scenario.constants';
import formatter from '../../../utils/text-field-formatter';

export default {
  components: {
    OutputTextField,
    CourseGauge,
  },
  computed: {
    ...mapState({
      basic: (state) => state[SCENARIO].basic,
    }),
  },
  methods: {
    formatter,
  },
};
</script>
