<template>
  <div class="twa-gauge ma-2 text-center">
    <v-img width="160" :src="TWA_GAUGE_PLATE" />
    <v-img width="160" class="twa-indicator" :src="WIND_POINTER" ref="windPointer" />
    <div class="mt-2 font-weight-bold">TWA</div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import TWA_GAUGE_PLATE from '../../../assets/img/dashboard/Gauge-True_Wind-Plate_img01.svg';
import WIND_POINTER from '../../../assets/img/dashboard/windpointer.svg';
import { MODULE_NAME as SCENARIO } from '../../../store/scenario/scenario.constants';

export default {
  data: () => ({
    TWA_GAUGE_PLATE,
    WIND_POINTER,
  }),
  computed: {
    ...mapState({
      basic: (state) => state[SCENARIO].basic,
    }),
    twa() {
      return this.basic.twa;
    },
  },
  watch: {
    twa(newTwa) {
      if (this.$refs && this.$refs.windPointer) {
        this.$refs.windPointer.$el.style.transform = `rotate(${newTwa}deg)`;
      }
    },
  },
};
</script>
<style scoped>
.twa-gauge {
  position: relative;
}

.twa-indicator {
  position: absolute;
  top: 0;
  margin: 0 auto;
}
</style>
