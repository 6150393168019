import Vuex from 'vuex';

import scenario from './scenario/scenario.store';
import panels from './panels/panels.store';
import auth from './auth/auth.store';

export default new Vuex.Store({
  modules: {
    scenario,
    panels,
    auth,
  },
});
