import Vue from 'vue';
import '@babel/polyfill';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './plugins/router';
import './plugins/vuex';
import store from './store';

Vue.config.productionTip = false;

new Vue({
  store,
  vuetify,
  router,
  render: (h) => h(App),
}).$mount('#app');
