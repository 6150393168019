<template>
  <div>
    <v-img
      :src="FORCE_DIRECTION_PLATE"
      width="160"
      height="160"
      contain
    />
    <v-img
      :src="forceRotationDirection"
      width="200"
      height="200"
      contain
      class="direction-arrows"
    />
    <v-img
      :src="FORCE_POINTER"
      class="force-pointer"
      ref="forcePointer"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import FORCE_DIRECTION_PLATE from '../../../assets/img/dashboard/Gauge-Rotor_Force_Direction-Plate_img01.svg';
import FORCE_ROTATION_DIRECTION_CW from '../../../assets/img/dashboard/Gauge-Rotor_Force_Direction-Rotating_direction_CW_img01.svg';
import FORCE_ROTATION_DIRECTION_CCW from '../../../assets/img/dashboard/Gauge-Rotor_Force_Direction-Rotating_direction_CCW_img01.svg';
import FORCE_POINTER from '../../../assets/img/dashboard/forcepointer.svg';
import { MODULE_NAME as PANELS } from '../../../store/panels/panels.constants';

export default {
  props: {
    forceDirection: {
      type: Number,
    },
  },
  data: () => ({
    FORCE_DIRECTION_PLATE,
    FORCE_POINTER,
  }),
  computed: {
    ...mapState({
      singleOperationOutput: (state) => state[PANELS].operation.singleOutput,
    }),
    forceRotationDirection() {
      if (this.singleOperationOutput.optimalRPM < 0) {
        return FORCE_ROTATION_DIRECTION_CCW;
      }
      return FORCE_ROTATION_DIRECTION_CW;
    },
  },
  watch: {
    forceDirection(newDir) {
      if (this.$refs && this.$refs.forcePointer) {
        this.$refs.forcePointer.$el.style.transform = `rotate(${newDir}deg)`;
      }
    },
  },
};
</script>
<style scoped>
.direction-arrows {
  position: absolute;
  top: 3px;
  left: -10px;
}

.force-pointer {
  position: absolute;
  top: 25px;
  left: 20px;
  transition: .4s transform;
}
</style>
