import Vue from 'vue';
import VueRouter from 'vue-router';
import netlifyIdentity from 'netlify-identity-widget';
import { initAuth } from '../components/auth/auth.service';

// components
import Login from '../components/auth/Login.vue';
import OperationPanel from '../components/panels/operation/OperationPanel.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Login,
  },
  {
    path: '/login',
    redirect: '/',
  },
  {
    path: '/operation',
    component: OperationPanel,
  },
];

const router = new VueRouter({
  routes,
});

const protectedRoutes = ['/operation'];
initAuth(netlifyIdentity);

router.beforeEach((to, from, next) => {
  console.log(to);
  if (to.path === '/login') {
    return next();
  }

  if (protectedRoutes.includes(to.path)) {
    if (netlifyIdentity.currentUser() === null) {
      return next('/login');
    }
  }

  return next();
});

export default router;
