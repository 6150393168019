<template>
  <v-card class="pa-5" outlined tile>
    <OutputTextField :value="totalPropulsionPower" suffix="kW">
      <template v-slot:label>
        Total propulsion power
      </template>
    </OutputTextField>
    <OutputTextField :value="totalPowerConsumption" suffix="kW" class="mt-2">
      <template v-slot:label>
        Total power consumption
      </template>
    </OutputTextField>
  </v-card>
</template>
<script>
import { mapState } from 'vuex';
import OutputTextField from '../shared/OutputTextField.vue';
import { MODULE_NAME as PANELS } from '../../../store/panels/panels.constants';
import { MODULE_NAME as SCENARIO } from '../../../store/scenario/scenario.constants';

export default {
  components: {
    OutputTextField,
  },
  computed: {
    ...mapState({
      singleOperationOutput: (state) => state[PANELS].operation.singleOutput,
      rotorSailAmount: (state) => state[SCENARIO].advanced.rotorSailAmount,
    }),
    totalPropulsionPower() {
      return this.rotorSailAmount * (this.singleOperationOutput.propulsionPower || 0);
    },
    totalPowerConsumption() {
      return this.rotorSailAmount * (this.singleOperationOutput.powerConsumption || 0);
    },
  },
};
</script>
