export const MODULE_NAME = 'auth';

export const GETTERS = {
  LOGGED_IN: `${MODULE_NAME}/LOGGED_IN`,
};

export const ACTIONS = {
  SET_USER: `${MODULE_NAME}/SET_USER`,
};

export const MUTATIONS = {
  SET_USER: `${MODULE_NAME}/SET_USER`,
};
