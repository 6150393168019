<template>
  <div class="course-gauge ma-2">
    <v-img width="300" :src="ROTOR_COURSE_GAUGE_PLATE" />
    <v-img width="300" class="course-indicator" :src="ROTOR_COURSE_GAUGE_INDICATOR" />
  </div>
</template>
<script>
import ROTOR_COURSE_GAUGE_INDICATOR from '../../../assets/img/dashboard/v1/Rotor_Course_Gauge_Indicator_v01.svg';
import ROTOR_COURSE_GAUGE_PLATE from '../../../assets/img/dashboard/v1/Rotor_Course_Gauge_Plate_v01.svg';

export default {
  data: () => ({
    ROTOR_COURSE_GAUGE_INDICATOR,
    ROTOR_COURSE_GAUGE_PLATE,
  }),
};
</script>
<style scoped>
.course-gauge {
  position: relative;
}

.course-indicator {
  position: absolute;
  top: 0;
  margin: 0 auto;
}
</style>
