<template>
  <v-expansion-panel class="ma-0">
    <v-expansion-panel-header>
      <h2>Advanced</h2>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div class="mb-2">
        <h3>Rotor Sail</h3>
      </div>
      <v-row>
        <v-col>
          <v-text-field
            color="secondary"
            label="Amount"
            type="number"
            :min="INPUT_VALIDATION.ROTOR_SAIL_AMOUNT_MIN"
            :max="INPUT_VALIDATION.ROTOR_SAIL_AMOUNT_MAX"
            :value="advancedInput.rotorSailAmount"
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select
            color="secondary"
            label="Size"
            :items="rotorSailSizes"
            :value="advancedInput.rotorSail.size"
            dense
            hide-details
            disabled
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            color="secondary"
            label="Maximum RPM"
            suffix="RPM"
            type="number"
            :min="-selectedRotorSail.maxRPM"
            :max="selectedRotorSail.maxRPM"
            :value="advancedInput.rotorSail.maxRPM"
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            color="secondary"
            label="Forwardforce limit"
            suffix="kN"
            type="number"
            :value="advancedInput.rotorSail.forwardForceLimit"
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            color="secondary"
            label="Lateralforce limit"
            suffix="kN"
            type="number"
            :value="advancedInput.rotorSail.lateralForceLimit"
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <div class="mt-5 mb-2">
        <h3>Other</h3>
      </div>
      <v-row>
        <v-col>
          <v-text-field
            color="secondary"
            label="Propulsion Efficiency"
            suffix="%"
            type="number"
            :value="advancedInput.other.propulsionEfficiencyPercentage"
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            color="secondary"
            label="Air Temperature"
            suffix="°C"
            type="number"
            :value="advancedInput.other.airTemperature"
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            color="secondary"
            label="Air Pressure"
            suffix="Pa"
            type="number"
            :value="advancedInput.other.airPressure"
            dense
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import { mapState } from 'vuex';
import { ROTOR_SAIL_DEFAULTS, INPUT_VALIDATION } from '../../config/constants';
import { MODULE_NAME as SCENARIO } from '../../store/scenario/scenario.constants';

export default {
  data: () => ({
    INPUT_VALIDATION,
  }),
  computed: {
    ...mapState({
      advancedInput: (state) => state[SCENARIO].advanced,
    }),
    rotorSailSizes() {
      return ROTOR_SAIL_DEFAULTS.map((rs) => rs.size);
    },
    selectedRotorSail() {
      return ROTOR_SAIL_DEFAULTS.find((rs) => rs.size === this.advancedInput.rotorSail.size) || {};
    },
  },
};
</script>
