<template>
  <div>
    <span class="text-h6">True Wind</span>

    <v-card outlined tile>
      <v-container>
        <v-row dense>
          <v-col :cols="6">
            <OutputTextField
              :minWidth="60"
              suffix="m/s"
              :value="basic.tws"
              :formatter="(val) => formatter(val, 1)"
            >
              <template v-slot:label>
                Speed
              </template>
            </OutputTextField>
          </v-col>
          <v-col :cols="6">
            <OutputTextField
              :minWidth="60"
              suffix="deg"
              :value="singleOperationOutput.twd"
              :formatter="(val) => formatter(val, 0)"
            >
              <template v-slot:label>
                Dir
              </template>
            </OutputTextField>
          </v-col>

          <v-col :cols="6">
            <OutputTextField
              :minWidth="60"
              suffix="deg"
              :value="basic.twa"
              :formatter="(val) => formatter(val, 0)"
            >
              <template v-slot:label>
                Angle
              </template>
            </OutputTextField>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <TrueWindGauge />
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import OutputTextField from '../shared/OutputTextField.vue';
import TrueWindGauge from './TrueWindGauge.vue';
import { MODULE_NAME as SCENARIO } from '../../../store/scenario/scenario.constants';
import { MODULE_NAME as PANELS } from '../../../store/panels/panels.constants';
import formatter from '../../../utils/text-field-formatter';

export default {
  components: {
    OutputTextField,
    TrueWindGauge,
  },
  computed: {
    ...mapState({
      basic: (state) => state[SCENARIO].basic,
      singleOperationOutput: (state) => state[PANELS].operation.singleOutput,
    }),
  },
  methods: {
    formatter,
  },
};
</script>
