<template>
  <div>
    <v-container>
      <v-row>
        <v-expansion-panels flat tile multiple :value="[0]">
          <BasicInput />
          <AdvancedInput />
        </v-expansion-panels>
      </v-row>

      <v-row class="mt-5">
        <v-col>
          <v-btn block color="secondary" @click="calculate">
            Update
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import BasicInput from './BasicInput.vue';
import AdvancedInput from './AdvancedInput.vue';
import { ACTIONS as SCENARIO_ACTIONS } from '../../store/scenario/scenario.constants';

export default {
  components: {
    BasicInput,
    AdvancedInput,
  },
  methods: {
    ...mapActions({
      calculate: SCENARIO_ACTIONS.CALCULATE,
    }),
  },
};
</script>
