<template>
  <v-navigation-drawer app permanent clipped width="350">
    <InputBox />
  </v-navigation-drawer>
</template>
<script>
import InputBox from './scenario/InputBox.vue';

export default {
  components: {
    InputBox,
  },
};
</script>
