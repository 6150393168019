<template>
  <div>
    <span class="text-h6">Rotor Sails</span>

    <div class="d-flex flex-wrap justify-center ma-2">
      <RotorSailTotals class="rotorsail-totals" />
    </div>

    <div class="d-flex flex-wrap justify-center">
      <RotorSail v-for="i in rotorSailAmount" :key="i" :number="i" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import RotorSail from './RotorSail.vue';
import RotorSailTotals from './RotorSailTotals.vue';
import { MODULE_NAME as SCENARIO } from '../../../store/scenario/scenario.constants';

export default {
  components: {
    RotorSail,
    RotorSailTotals,
  },
  computed: {
    ...mapState({
      rotorSailAmount: (state) => state[SCENARIO].advanced.rotorSailAmount,
    }),
  },
};
</script>
<style scoped>
  .rotorsail-totals {
    width: 50%;
  }
</style>
