<template>
  <v-card outlined tile>
    <div class="rotorsail-number font-weight-thin">
      Rotor Sail #{{ number }}
    </div>

    <div class="d-flex flex-wrap justify-space-between align-center">
      <ForceGauge class="pa-2" :forceDirection="forceDirection" />

      <v-responsive max-width="265">
        <div class="compact">
          <OutputTextField :value="singleOperationOutput.propulsionPower" suffix="kW">
            <template v-slot:label>
              Propulsion Power
            </template>
          </OutputTextField>
          <OutputTextField :value="singleOperationOutput.powerConsumption" suffix="kW">
            <template v-slot:label>
              Power Consumption
            </template>
          </OutputTextField>
          <OutputTextField :value="singleOperationOutput.forwardForce" suffix="kN">
            <template v-slot:label>
              Forward Force
            </template>
          </OutputTextField>
          <OutputTextField :value="singleOperationOutput.lateralForce" suffix="kN">
            <template v-slot:label>
              Lateral Force
            </template>
          </OutputTextField>
          <OutputTextField
            :value="singleOperationOutput.optimalRPM"
            suffix="RPM"
            :formatter="(val) => formatter(val, 0)"
          >
            <template v-slot:label>
              Speed
            </template>
          </OutputTextField>
        </div>
      </v-responsive>
    </div>
  </v-card>
</template>
<script>
import { mapState } from 'vuex';
import { MODULE_NAME as PANELS } from '../../../store/panels/panels.constants';
import OutputTextField from '../shared/OutputTextField.vue';
import ForceGauge from './ForceGauge.vue';
import formatter from '../../../utils/text-field-formatter';

export default {
  props: {
    number: {
      type: Number,
      required: true,
    },
  },
  components: {
    OutputTextField,
    ForceGauge,
  },
  computed: {
    ...mapState({
      singleOperationOutput: (state) => state[PANELS].operation.singleOutput,
    }),
    forceDirection() {
      return this.singleOperationOutput.forceDirection;
    },
  },
  methods: {
    formatter,
  },
};
</script>
<style scoped>
.rotorsail-number {
  position: absolute;
  width: 100%;
  text-align: center;
}

.compact {
  transform: scale(0.8);
  transform-origin: left;
  width: 280px;
}
</style>
