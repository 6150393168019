import HTTP from '../../plugins/axios';

export default {
  calculate(basicInput, advancedInput, maxThrust) {
    return HTTP.post('/calculate', {
      ...basicInput,
      rotorSailAmount: advancedInput.rotorSailAmount,
      rotorSail: { ...advancedInput.rotorSail },
    }, {
      params: { maxThrust },
    });
  },
};
