<template>
  <v-app>
    <v-app-bar app color="primary" clipped-left>
      <div class="d-flex align-center">
        <v-img
          alt="Norsepower Logo"
          contain
          src="./assets/img/norsepower-logo.png"
          width="48"
          class="mr-2"
        />
      </div>
      <v-app-bar-title>
        <div class="d-flex">
          <v-col>Norsepower</v-col>
          <v-divider vertical />
          <v-col>Virtual Rotor Sail</v-col>
        </div>
      </v-app-bar-title>
      <v-spacer />
      <v-icon v-if="loggedIn" @click="logout">mdi-logout</v-icon>
    </v-app-bar>

    <SideBar v-if="loggedIn" />

    <v-main>
      <router-view />
      <panel-navigation v-if="loggedIn" />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import netlifyIdentity from 'netlify-identity-widget';
import SideBar from './components/SideBar.vue';
import PanelNavigation from './components/panels/shared/PanelNavigation.vue';
// eslint-disable-next-line
import { ACTIONS as AUTH_ACTIONS, GETTERS as AUTH_GETTERS } from './store/auth/auth.constants';

export default {
  components: {
    SideBar,
    PanelNavigation,
  },
  computed: {
    ...mapGetters({
      loggedIn: AUTH_GETTERS.LOGGED_IN,
    }),
  },
  created() {
    netlifyIdentity.on('logout', () => {
      this.setUser(null);
      this.$router.replace('/login');
    });
  },
  methods: {
    ...mapActions({
      setUser: AUTH_ACTIONS.SET_USER,
    }),
    logout() {
      netlifyIdentity.logout();
    },
  },
};
</script>
