import initialState from './panels.state';
import { ACTIONS, MUTATIONS } from './panels.constants';

export default {
  state: initialState(),
  actions: {
    [ACTIONS.SET_SINGLE_OPERATION_OUTPUT]: ({ commit }, singleOutput) => {
      commit(MUTATIONS.SET_SINGLE_OPERATION_OUTPUT, singleOutput);
    },
  },
  mutations: {
    [MUTATIONS.SET_SINGLE_OPERATION_OUTPUT]: (state, singleOutput) => {
      state.operation.singleOutput = singleOutput;
    },
  },
};
