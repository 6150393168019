import initialState from './auth.state';
import { ACTIONS, MUTATIONS, GETTERS } from './auth.constants';

export default {
  state: initialState(),
  getters: {
    [GETTERS.LOGGED_IN]: (state) => state.user !== null,
  },
  actions: {
    [ACTIONS.SET_USER]: ({ commit }, user) => {
      commit(MUTATIONS.SET_USER, user);
    },
  },
  mutations: {
    [MUTATIONS.SET_USER]: (state, user) => {
      state.user = user;
    },
  },
};
