<template>
  <div class="d-flex justify-center align-center fill-height">
    <v-btn color="secondary" @click="openLoginModal">
      Login
    </v-btn>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import netlifyIdentity from 'netlify-identity-widget';
import { ACTIONS as AUTH_ACTIONS } from '../../store/auth/auth.constants';

export default {
  created() {
    if (netlifyIdentity.currentUser()) {
      this.setUser(netlifyIdentity.currentUser());
      this.$router.replace('/operation');
    }

    netlifyIdentity.on('login', (user) => {
      this.setUser(user);
      this.$router.replace('/operation');
      setTimeout(() => {
        netlifyIdentity.close();
      }, 1500);
    });
  },
  methods: {
    ...mapActions({
      setUser: AUTH_ACTIONS.SET_USER,
    }),
    openLoginModal() {
      netlifyIdentity.open('login');
    },
  },
};
</script>
