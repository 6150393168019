<template>
  <div class="d-flex align-center">
    <v-responsive :min-width="minWidth">
      <div>
        <slot name="label"></slot>
      </div>
    </v-responsive>
    <div>
      <v-text-field
        color="secondary"
        :suffix="suffix"
        :value="formatter(value)"
        outlined
        dense
        readonly
        hide-details
        full-width
      ></v-text-field>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
    },
    suffix: {
      type: String,
    },
    formatter: {
      type: Function,
      default: (val) => {
        if (typeof val === 'number') {
          return val.toFixed(2);
        }
        return val;
      },
    },
    minWidth: {
      type: Number,
      default: 160,
    },
  },
};
</script>
