<template>
  <div>
    <span class="text-h6">Propulsion</span>

    <v-card outlined tile>
        <div class="d-flex flex-column text-center">
          <div class="font-weight-bold">
            Max Thrust
          </div>
          <div>
            {{ maxThrust }} kW
          </div>
          <v-slider
            color="white"
            vertical
            :value="maxThrust"
            :min="INPUT_VALIDATION.MAX_THRUST_MIN"
            :step="INPUT_VALIDATION.MAX_THRUST_STEP"
            :max="INPUT_VALIDATION.MAX_THRUST_MAX"
            class="fill-height-slider"
            @input="updateMaxThrust"
            @end="calculate"
          ></v-slider>
        </div>
    </v-card>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { INPUT_VALIDATION } from '../../../config/constants';
import { MODULE_NAME as SCENARIO, ACTIONS as SCENARIO_ACTIONS } from '../../../store/scenario/scenario.constants';

export default {
  data: () => ({
    INPUT_VALIDATION,
  }),
  computed: {
    ...mapState({
      maxThrust: (state) => state[SCENARIO].maxThrust,
    }),
  },
  methods: {
    ...mapActions({
      calculate: SCENARIO_ACTIONS.CALCULATE,
      updateMaxThrust: SCENARIO_ACTIONS.UPDATE_MAX_THRUST,
    }),
  },
};
</script>
<style scoped>
  .fill-height-slider >>> .v-slider {
    min-height: 47.9em !important;
  }
</style>
