export const INPUT_VALIDATION = {
  SOG_MIN: 1,
  SOG_STEP: 0.1,
  SOG_MAX: 30,

  COG_MIN: 0,
  COG_MAX: 359,

  TWS_MIN: 1,
  TWS_STEP: 0.1,
  TWS_MAX: 25,

  TWA_MIN: 0,
  TWA_MAX: 359,

  ROTOR_SAIL_AMOUNT_MIN: 1,
  ROTOR_SAIL_AMOUNT_MAX: 6,

  MAX_THRUST_MIN: 0,
  MAX_THRUST_STEP: 1000,
  MAX_THRUST_MAX: 25000,
};

export const ROTOR_SAIL_DEFAULTS = [
  {
    height: 35,
    diameter: 5,
    size: '5x35',
    maxRPM: 180,
    forwardForceLimit: 350,
    lateralForceLimit: 350,
  },
];

export const DEFAULT_OTHER = {
  PROPULSION_EFFICIENCY: 70,
  AIR_TEMPERATURE: 20,
  AIR_PRESSURE: 101300,
};

export default {
  INPUT_VALIDATION,
  ROTOR_SAIL_DEFAULTS,
  DEFAULT_OTHER,
};
