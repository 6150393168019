<template>
  <panel-container>
    <v-responsive height="100%">
      <v-row dense>
        <v-col :cols="1">
          <Propulsion />
        </v-col>

        <v-col :cols="8">
          <RotorSails />
        </v-col>

        <v-col :cols="3">
          <div class="d-flex flex-wrap">
            <Course />
            <TrueWind />
            <RotorSailControl class="rotor-sail-control"/>
          </div>
        </v-col>
      </v-row>
    </v-responsive>
  </panel-container>
</template>
<script>
import PanelContainer from '../shared/PanelContainer.vue';
import Propulsion from './Propulsion.vue';
import RotorSails from './RotorSails.vue';
import Course from './Course.vue';
import TrueWind from './TrueWind.vue';
import RotorSailControl from './RotorSailControl.vue';

export default {
  components: {
    PanelContainer,
    Propulsion,
    RotorSails,
    Course,
    TrueWind,
    RotorSailControl,
  },
};
</script>
<style scoped>
.rotor-sail-control {
  width: 100%;
}
</style>
