<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <h2>Basic</h2>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row align="center">
        <v-col :cols="8" class="py-0">
          <v-slider
            color="secondary"
            label="SOG"
            :min="INPUT_VALIDATION.SOG_MIN"
            :step="INPUT_VALIDATION.SOG_STEP"
            :max="INPUT_VALIDATION.SOG_MAX"
            :value="basic.sog"
            hide-details
            class="pt-3"
            flat
            @input="(val) => onUpdate('sog', val)"
          ></v-slider>
        </v-col>
        <v-col :cols="4" class="py-0">
          <v-text-field
            color="secondary"
            suffix="kn"
            type="number"
            :min="INPUT_VALIDATION.SOG_MIN"
            :step="INPUT_VALIDATION.SOG_STEP"
            :max="INPUT_VALIDATION.SOG_MAX"
            :value="basic.sog"
            dense
            hide-details
            @input="(val) => onUpdate('sog', val)"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col :cols="8" class="py-0">
          <v-slider
            color="secondary"
            label="COG"
            :min="INPUT_VALIDATION.COG_MIN"
            :max="INPUT_VALIDATION.COG_MAX"
            :value="basic.cog"
            hide-details
            class="pt-3"
            flat
            @input="(val) => onUpdate('cog', val)"
          ></v-slider>
        </v-col>
        <v-col :cols="4" class="py-0">
          <v-text-field
            color="secondary"
            suffix="deg"
            type="number"
            :min="INPUT_VALIDATION.COG_MIN"
            :max="INPUT_VALIDATION.COG_MAX"
            :value="basic.cog"
            dense
            hide-details
            @input="(val) => onUpdate('cog', val)"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col :cols="8" class="py-0">
          <v-slider
            color="secondary"
            label="TWS"
            :min="INPUT_VALIDATION.TWS_MIN"
            :step="INPUT_VALIDATION.TWS_STEP"
            :max="INPUT_VALIDATION.TWS_MAX"
            :value="basic.tws"
            hide-details
            class="pt-3"
            flat
            @input="(val) => onUpdate('tws', val)"
          ></v-slider>
        </v-col>
        <v-col :cols="4" class="py-0">
          <v-text-field
            color="secondary"
            suffix="m/s"
            type="number"
            :min="INPUT_VALIDATION.TWS_MIN"
            :step="INPUT_VALIDATION.TWS_STEP"
            :max="INPUT_VALIDATION.TWS_MAX"
            :value="basic.tws"
            dense
            hide-details
            @input="(val) => onUpdate('tws', val)"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col :cols="8" class="py-0">
          <v-slider
            color="secondary"
            label="TWA"
            :min="INPUT_VALIDATION.TWA_MIN"
            :max="INPUT_VALIDATION.TWA_MAX"
            :value="basic.twa"
            hide-details
            class="pt-3"
            flat
            @input="(val) => onUpdate('twa', val)"
          ></v-slider>
        </v-col>
        <v-col :cols="4" class="py-0">
          <v-text-field
            color="secondary"
            suffix="deg"
            type="number"
            :min="INPUT_VALIDATION.TWA_MIN"
            :max="INPUT_VALIDATION.TWA_MAX"
            :value="basic.twa"
            dense
            hide-details
            @input="(val) => onUpdate('twa', val)"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { INPUT_VALIDATION } from '../../config/constants';
import { MODULE_NAME as SCENARIO, ACTIONS as SCENARIO_ACTIONS } from '../../store/scenario/scenario.constants';

export default {
  data: () => ({
    INPUT_VALIDATION,
  }),
  computed: {
    ...mapState({
      basic: (state) => state[SCENARIO].basic,
    }),
  },
  methods: {
    ...mapActions({
      setBasicInput: SCENARIO_ACTIONS.SET_BASIC_INPUT,
    }),
    onUpdate(prop, val) {
      const updatedBasic = { ...this.basic };
      updatedBasic[prop] = val;
      this.setBasicInput(updatedBasic);
    },
  },
};
</script>
